.chart {
    width: 100%;
    max-width: 870px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: background 0.3s, box-shadow 0.3s;
}

.chart:hover {
    background: #f8f9fc;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.chartTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.chart .recharts-tooltip-wrapper {
    font-size: 12px;
    color: #333;
}

.chart .recharts-tooltip-item {
    color: #555;
}

/* MEDIA QUERIES */

/* For tablets (landscape and portrait) */
@media only screen and (max-width: 768px) {
    .chart {
        padding: 15px;
        max-width: 100%; /* Make sure it takes full width on tablets */
        width: 70%
    }
    
    .chartTitle {
        font-size: 16px;
    }

    .date {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* For mobile devices */
@media only screen and (max-width: 480px) {
    .chart {
        padding: 10px;
        width: 100%; /* Full width on mobile */
        margin-left: 18px;
        justify-content: center;
        align-content: center;
        
    }
    
    .chartTitle {
        font-size: 14px; /* Smaller title font for mobile */
    }

    .date {
        flex-direction: column;
        align-items: flex-start;
    }
}
