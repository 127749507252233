/* Existing styles remain the same ... */

.maintenance-wrapper {
  width: 100%;
}

.maintenance-request {
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  width: 10%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.maintenance-text {
  padding-left: 20px;
  font-size: 1.2rem;
  color: #333;
}

/* Custom status badge styling */
.status-badge {
  display: inline-block;
  width: 63px;
  height: 24px;
  line-height: 24px;
  padding: 2px 5px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

/* Status colors */
.status-badge.pending {
  background-color: rgba(255, 243, 210, 1);
  color: rgba(235, 122, 18, 1);
}

.status-badge.completed {
  background-color: rgba(211, 252, 197, 1);
  color: rgba(0, 191, 53, 1);
}

.status-badge.in-progress {
  background-color: rgba(241, 253, 255, 1);
  color: rgba(42, 102, 176, 1);
}

.status-badge.declined {
  background-color: rgba(255, 210, 210, 1);
  color: rgba(233, 18, 18, 1);
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjusted styles for the DataGrid to prevent scroll bars */
.MuiDataGrid-root {
  border: 0;
  overflow: hidden !important;
}

.MuiDataGrid-main {
  overflow: hidden !important; /* Ensure no scrollbars */
}

.MuiDataGrid-virtualScroller {
  overflow: hidden !important;
  padding-right: 0 !important; /* Remove any padding that could cause overflow */
}

/* Forcefully hide any scrollbars */
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none; /* Hide scrollbar for Webkit browsers */
}

.MuiDataGrid-virtualScroller {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

/* Additional row styling for better alignment */
.MuiDataGrid-row {
  height: 52px;
  display: flex;
  align-items: center;
}

.MuiDataGrid-cell {
  display: flex;
  align-items: center;
}

/* Hide horizontal scrollbar container */
.MuiDataGrid-root .MuiDataGrid-scrollArea {
  display: none;
}


/* Media queries for responsiveness */

/* Tablets (768px and above) */
@media (max-width: 1024px) {
  .maintenance-request {
    padding: 15px;
  }

  .MuiDataGrid-cell {
    font-size: 11px;
  }

  .status-badge {
    font-size: 9px;
  }
}

/* Mobile devices (up to 768px) */
@media (max-width: 768px) {
  .maintenance-text h2 {
    font-size: 1.2rem;
  }

  .maintenance-request {
    padding: 10px;
    margin: 10px auto;
    width: 50%;
  }

  .MuiDataGrid-row {
    flex-direction: column;
    height: auto;
    
  }

  .MuiDataGrid-cell {
    font-size: 10px;
    text-align: center;
  }

  .status-badge {
    font-size: 8px;
    padding: 2px 5px;
  }
}

/* Small mobile devices (up to 480px) */
@media (max-width: 480px) {
  .maintenance-wrapper {
    padding: 10px;
    width: 20px;
    margin-left: -19px;
  }

  .maintenance-text h2 {
    font-size: 1rem;
  }

  .maintenance-request {
    padding: 5px;
    margin: 5px auto;
    width: 5%;
  }

  .MuiDataGrid-cell {
    font-size: 9px;
  }

  .status-badge {
    font-size: 7px;
    padding: 1px 4px;
  }
}
