.wallet-wrapper {
  padding: 20px;
  background-color: #f7f8fc;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 5px;
}

p {
  color: #666;
  font-size: 14px;
}

.wallet-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 1080px;
}

.wallet-balance {
  color: white;
  padding: 20px;
  border-radius: 20px;
  width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.wallets {
  padding: 20px;
  border-radius: 20px;
  width: 88%;
  height: 140px;
  gap: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  background-image: url(../../img/wallet.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.wallets::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 183px;
  height: 50px;
  background-image: url(../../img/Sphere-Blue-Glossy.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}

.balance-container {
  display: flex;
  align-items: center;
}

.visibility-icon {
  cursor: pointer;
  margin-left: 10px;
  color: white;
}

.wallets h2, h3 {
  color: white;
}

.wallet-actions {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}

.wallet-button {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
}

.wallet-button.fund {
  background-color: rgba(212, 216, 251, 1);
  color: rgba(42, 102, 176, 1);
}

.wallet-button.send {
  background-color: rgba(243, 194, 251, 1);
  color: rgba(106, 6, 96, 1);
}

.wallet-button.request {
  background-color: rgba(202, 253, 178, 1);
  color: rgba(6, 84, 32, 1);
}

.recent-transactions {
  background: white;
  padding: 20px;
  margin-left: 50px;
  border-radius: 10px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.recent-transactions h3 {
  color: #333;
}

.transaction {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.transaction-icon {
  font-size: 16px;
  margin-right: 10px;
}

.transaction-icon.income {
  color: #008c47;
}

.transaction-icon.expense {
  color: #b20043;
}

.amount.income {
  color: #008c47;
}

.amount.expense {
  color: #b20043;
}

.transaction-history {
  background: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction-table input[type="text"] {
  padding: 8px;
  width: 178px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.filter-button,
.export-button {
  padding: 8px 12px;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.status {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  display: inline-block;
}

.status.successful {
  background-color: rgba(211, 252, 197, 1);
  color: rgba(0, 191, 53, 1);
}

.status.failed {
  background-color: rgba(255, 210, 210, 1);
  color: rgba(233, 18, 18, 1);
}

.status.reversed {
  background-color: rgba(255, 243, 210, 1);
  color: rgba(235, 122, 18, 1);
}

/* Modal Styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.send-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}


/* Responsive Media Queries */
@media (max-width: 768px) { /* Tablet screens */

 
  .wallet-section {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    width: 115%;
    padding: 20px;
    gap: 10px;
  }

  .wallet-balance,
  .wallets {
    width: 90%; /* Full width */
  }

  .wallet-actions {
    gap: 10px; /* Reduce gap between actions */
  }

  .recent-transactions {
    margin-left: 0; /* Remove extra margin */
  }

  .transaction-history {
   width: 105%;
   margin-left: 12px;
  }

  .transaction-table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling */
  }

  .transaction-table table {
    min-width: 500px; /* Set a minimum width */
  }

}

@media (max-width: 480px) { /* Mobile screens */
  h2 {
    font-size: 20px; /* Adjust heading size */
  }

  p {
    font-size: 12px; /* Reduce paragraph font size */
  }

  .wallet-balance,
  .wallets {
    padding: 15px; /* Reduce padding */
  }

  .wallet-actions {
    flex-direction: column; /* Stack buttons vertically */
  }

  .wallet-button {
    font-size: 12px; /* Reduce button font size */
    padding: 8px 10px; /* Adjust padding */
  }

  table th,
  table td {
    font-size: 12px; /* Reduce table font size */
  }
}

