.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    text-align: left;
    
  }
  
  .close-button {
    float: right;
    border: none;
    background: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .modal-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .download-button,
  .print-button {
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .download-button:hover,
  .print-button:hover {
    background-color: #e0e0e0;
  }

  .modal-actions button {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-right: 10px;
    cursor: pointer;
  }
  