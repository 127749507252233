.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .pin-inputs {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pin-inputs input {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .confirm-button {
    background-color: #3578E5;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  