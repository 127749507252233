/* Default sidebar styles */
.sidebar {
  margin-top: 0;
  width: 250px;
  height: calc(100vh - 0px);
  background: rgb(251, 251, 255);
  position: sticky;
  top: 0;
  font-family: 'Nunito', sans-serif;
  transition: all 0.3s ease;
  z-index: 999;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebarList {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.sidebarListItem {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-bottom: 10px;
}

.sidebarListItem.active {
  border-right: 5px solid rgba(42, 102, 176, 1);
  color: rgba(42, 102, 176, 1);
  background: #f0f0f0;
}

.sidebarListItem:hover {
  background: #f8f8f8;
  color: rgba(42, 102, 176, 1);
}

.sidebarLink {
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: flex;
  align-items: center;
}

.sidebarIcon {
  margin-right: 20px;
  font-size: 18px;
}

.logOut {
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

/* Menu Button Styles */
.menuButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 1001;
  display: none;
  background: rgb(163, 196, 236);
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menuButton:hover {
  background: rgba(42, 102, 176, 1);
}

.sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar.open {
  transform: translateX(0);
}

/* Tablet Styles */
@media only screen and (max-width: 1024px) {
  .menuButton {
    display: block;
  }

  .sidebar {
    width: 0;
    height: 100vh;
    background: rgb(251, 251, 255);
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebarWrapper {
    padding: 60px 15px 15px;
  }

  .sidebarListItem {
    padding: 12px;
    font-size: 13px;
  }

  .sidebarIcon {
    margin-right: 15px;
    font-size: 16px;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 480px) {
  .menuButton {
    left: 10px;
    padding: 6px;
    top: 0;
  }

  .sidebar {
    width: 0;
    height: 100vh;
    background: rgb(251, 251, 255);
  }

  .sidebar.open {
    width: 100vw;
  }

  .sidebarWrapper {
    padding: 70px 10px 10px;
  }

  .sidebarListItem {
    padding: 15px;
    font-size: 16px;
    margin-bottom: 5px;
    justify-content: flex-start;
  }

  .sidebarIcon {
    margin-right: 15px;
    font-size: 20px;
  }

  .sidebarListItem.active {
    border-right: none;
    border-left: 5px solid rgba(42, 102, 176, 1);
  }

  .logOut {
    padding: 15px;
    margin-top: 20px;
  }
}


.sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar.open {
  transform: translateX(0);
}

@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    background-color:  rgb(163, 196, 236);
    width: 250px;
  }
  
  .closeSidebarButton {
    display: block !important;
  }
}

@media screen and (min-width: 769px) {
  .sidebar {
    transform: none;
  }
  
  .closeSidebarButton {
    display: none !important;
  }
}

/* Additional Header Integration Styles */
.header {
  position: relative; /* Ensure menu button positioning works */
}

/* Overlay for mobile sidebar */
.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.sidebar-overlay.show {
  display: block;
}