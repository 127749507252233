/* Modal Styles */
.modal-backdrop1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content p {
padding-bottom: 20px;
}
  
.modal-content {
    background-color: white;
    padding: 20px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}
  
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}
  
.form-group {
    margin-bottom: 15px;
}
  
.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 200px;
}


  
.form-group input {
 
    width: 90%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}


.form-group select {
    width: 96%; 
}
  
.send-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.send-button:hover {
    background-color: #0056b3;
}