.maintenance-wrapper {
    width: 100%;
  }
  
  .maintenance-request {
    margin: 20px;
    background-color: #fff;
    padding: 20px;
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .maintenance-text {
    padding-left: 20px;
    font-size: 1.2rem;
    color: #333;
  }
  
  
  .status-badge {
    display: inline-block;
    width: 75px; 
    height: 24px; 
    line-height: 24px; 
    padding: 2px 5px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 12px;
    color: white;
    text-align: center;
    overflow: hidden; 
    white-space: nowrap;
  }
  
  /* Status colors */
  .status-badge.pending {
    background-color: rgba(255, 243, 210, 1); /* Yellow for Pending */
    color: rgba(235, 122, 18, 1);
  }
  
  .status-badge.approved {
    background-color: rgba(211, 252, 197, 1); /* Green for Approved */
    color: rgba(0, 191, 53, 1);
  }
  
  /* Additional styles for the DataGrid to prevent scroll bars */
  .MuiDataGrid-root {
    border: 0;
    overflow: hidden;
  }
  
  .MuiDataGrid-virtualScroller {
    overflow: hidden !important; /* Ensure no vertical scroll */
  }
  
  /* Additional row styling for better alignment */
  .MuiDataGrid-row {
    height: 52px; 
    display: flex;
    align-items: center;
  }
  
  .MuiDataGrid-cell {
    display: flex;
    align-items: center;
  }
  
  
  @media (max-width: 768px) {
    .maintenance-text h2 {
      font-size: 1.2rem;
    }
  
    .maintenance-text p {
      font-size: 0.9rem;
    }
  
    .MuiDataGrid-root .MuiDataGrid-columnHeader {
      font-size: 0.8rem;
    }
  
    .MuiDataGrid-cell {
      font-size: 0.8rem;
      padding: 4px;
    }

    .maintenance-request {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .maintenance-wrapper {
      padding: 10px;
      width: 100%;
    }


  
    .MuiDataGrid-root .MuiDataGrid-columnHeader {
      font-size: 0.7rem;
    }
  
    .MuiDataGrid-cell {
      font-size: 0.7rem;
      padding: 2px;
    }
  }
  