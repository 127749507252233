/* Modal backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal content container */
  .modal-content {
    background: white;
    width: 300px;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Checkmark icon container */
  .accepted-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .accepted-icon img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e0f7e4;
    padding: 10px;
  }
  
  /* Modal title */
  .modal-content h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
  }
  
  /* Modal text */
  .modal-content p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  