.account-settings {
  width: 100%;
  margin: 20px;
  margin-left: 100px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.active1 {
  text-decoration: none;
}

.set {
  margin-top: 30px;
  margin-left: -500px;
  color: #000;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.tabs span {
  color: #757575;
  cursor: pointer;
}

.tabs .active1 {
  color: #2A66B0;
  font-weight: bold;
  border-bottom: 2px solid #2A66B0;
  padding-bottom: 5px;
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.profile-picture {
  border: 2px dashed #cfcfcf;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #a5a5a5;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.form-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
}

.form-group1 {
  display: flex;
  flex-direction: column;
  width: 20vw;
}

.form-group1 label {
  margin-bottom: 5px;
  color: #757575;
  width: 10vw;
}

.form-group1 input {
  padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;

}

.cancel-btn {
  background: #e0e0e0;
  color: #757575;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 10vw;
}

.save-btn {
  background: #2A66B0;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .account-settings {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .account-settings {
    width: 90%;
  }

  .form-section {
    grid-template-columns: 1fr;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }
}

@media (max-width: 480px) {

  .account-settings{
    width: 100%;
    margin-left: 15px;
  }
  .tabs {
    flex-direction: column;
  }

  .profile-picture {
    width: 100px;
    height: 100px;
  }

  .form-group1 input {
    width: 92%;
  }

  .form-group1 {
    width: 60vw;
  }

  .form-group1 label{
    width: 50vw;
  }

  .button-group button {
    width: 100%;
  }

  .button-group{
    width: 60vw;
  }

  .cancel-btn{
    margin-left: -15px;
  }
  .save-btn{
    margin-left: -15px;
  }

  .tabs .active1{
    border-bottom: none;
  }
}
