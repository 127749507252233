.help-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 70px;
  min-height: 80vh;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.help-text {
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px;
}

.help-bar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
}

.search-container1 {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.help-search {
  padding: 10px 40px 10px 30px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.search-icon1 {
  position: absolute;
  left: 10px;
  color: #666;
  pointer-events: none;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
}

.card {
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

.card:hover {
  transform: translateY(-10px);
}

.card h3 {
  font-family: Nunito, sans-serif;
  font-size: clamp(20px, 2vw, 24px);
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  color: black;
  margin: 15px 0;
}

.card p {
  color: #666;
  font-size: clamp(14px, 1.5vw, 16px);
  line-height: 1.5;
  margin: 0;
}

.card-icon {
  font-size: 24px;
  margin: 0 auto 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: white;
}

/* Icon background colors */
.faqs-icon {
  background-color: rgba(252, 253, 203, 1);
  color: rgba(255, 148, 0, 1);
}

.contact-icon {
  background-color: rgba(254, 226, 255, 1);
  color: rgba(118, 13, 166, 1);
}

.feedback-icon {
  background-color: rgba(235, 255, 226, 1);
  color: rgba(0, 191, 113, 1);
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .help-container {
    padding-top: 40px;
   
  }

  .cards-container {
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 30px auto;
  }

  .card {
    width: 100%;
    min-height: 200px;
  }

  .help-search {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .help-container {
    padding: 20px 10px;

  }

  .help-text {
    padding: 0 10px;
  }

  .card {
    padding: 15px;
  }

  .card-icon {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}