.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

/* Buttons styling */
.page-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid;
  padding: 8px 16px;
  border-radius: 5px;
  border-color: #ddd;
  cursor: pointer;
  font-size: 14px;
}

/* Icons within the buttons */
.page-button .pagination-icon {
  margin-right: 5px;
  margin-left: 5px;
}

/* Number container styling */
.page-numbers {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
}

/* Responsive adjustments */

/* Tablets (768px and above) */
@media (max-width: 1024px) {
  .pagination {
    flex-wrap: wrap; /* Allow wrapping if needed */
    justify-content: center; /* Center-align items */
    gap: 15px;
  }

  .page-button {
    padding: 6px 12px;
    font-size: 13px;
  }

  .page-numbers {
    gap: 8px;
    font-size: 13px;
  }
}

/* Mobile devices (up to 768px) */
@media (max-width: 768px) {
  .pagination {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    gap: 10px;
  }

  .page-button {
    width: 100%; /* Make buttons stretch full width */
    max-width: 200px; /* Add a max-width for larger screens */
    padding: 5px 10px;
    font-size: 12px;
  }

  .page-numbers {
    gap: 6px;
    font-size: 12px;
  }
}

/* Small mobile devices (up to 480px) */
@media (max-width: 480px) {
  .page-button {
    max-width: 150px;
    padding: 4px 8px;
    font-size: 11px;
  }

  .page-numbers {
    gap: 4px;
    font-size: 11px;
  }
}
