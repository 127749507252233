/* Base styles */
.forgot-password {
  width: 100%;
  min-height: 90vh;
  
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  gap: 50px;
}

/* Main content container */
.forgot-password .sign-up {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7fb;
  
  padding: 0;
  display: flex;
  
  justify-content: center;
  align-items: center;
}

.forgot-password .div {
  width: 100%;
  margin-left: 50px;
  max-width: 544px;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.frame {
  margin-left: -10px;
}

/* Typography */
.forgot-password .text-wrapper {
  font-family: "Nunito", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--foundation-graygray-800);
  margin-bottom: 1rem;
}

.forgot-password .p {
  font-family: "Nunito", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--foundation-graygray-400);
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

/* Form elements */
.forgot-password .label {
  font-family: "Nunito", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0;
  margin-left: 10px;
  color: #17191c;
  margin-bottom: 0.5rem;
  display: block;
}

.forgot-password .input-text-wrapper {
  width: 100%;
  min-height: 44px;
  background-color: #f2f4f9;
  border-radius: 8px;
  padding: 0.625rem;
  margin-bottom: 1.5rem;
}

.forgot-password .input-text {
  width: 100%;
  border: none;
  background: none;
  font-family: "Nunito", Helvetica, sans-serif;
  font-size: 0.875rem;
  color: var(--foundation-graygray-300);
  outline: none;
  
}

/* Button styles */
.forgot-password .button {
  width: 100%;
  margin-top: 1rem;
}

/* .forgot-password .primary-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #2a66b0;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

.forgot-password .primary-button:hover {
  background-color: #225491;
}

.forgot-password .visit-ITSA-website {
  font-family: "Nunito", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #ffffff;
  text-align: center;
  width: 100%;
  display: block;
}

/* Logo and image */
.forgot-password .ITSA-logo {
  width: 184px;
  height: 57px;
  position: absolute;
  top: rem;
  left: 1rem;
  background-image: url(../../img/DHMS_logo.png);
  background-size: contain;
  background-repeat: no-repeat;
}


.forgot-password .img {
 
  width: 90%;
  height: 80vh;
  margin-left: 9rem;
  display: block;
}

/* Responsive breakpoints */
@media screen and (max-width: 1024px) {
  .forgot-password .sign-up {
    width: 60%;
  }
  
  .forgot-password .div {
    width: 95%;
    padding: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .forgot-password {
    flex-direction: column;
  }
  
  .forgot-password .sign-up {
    width: 100%;
    min-height: 50vh;
    padding: 2rem 1rem;
    
  }
  
  .forgot-password .div {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }
  
  .forgot-password .ITSA-logo {
    position: relative;
    top: 0;
    left: -35px;
    margin: 1rem auto;
  }
  
  .forgot-password .img {
    max-width: 400px;
    margin: 2rem auto;
  }
}

@media screen and (max-width: 480px) {
  .forgot-password .div {
    padding: 2rem;
    gap: 0;
    top: 0;
  }
  
  .forgot-password .text-wrapper {
    font-size: 1.25rem;
  }
  
  .forgot-password .ITSA-logo {
    width: 140px;
    height: 43px;
    top: 0;
  }
  
  .forgot-password .img {
    max-width: 300px;
    top: 0;
    height: 40vh;
  }

  .forgot-password .sign-up {
    margin-left: -10px;
    width: 100%;
    height: 40vh;
  }

}