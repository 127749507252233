


@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Montserrat:wght@400;700&display=swap");

.login-container * {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
 font-family:"Nunito", Helvetica, sans-serif;;
}

body {
 background-color: #F7F7FB;
}

.login-container {
 display: flex;
 height: 100vh;
 width: 100%;
}


.left-section {
 flex: 1;
 padding: 40px;
 display: flex;
 flex-direction: column;
 background-color: white;
}

.logo-container {
 display: flex;
 align-items: center;
 margin-bottom: 40px;
}

.dhms-logo{
 width: 150px;
}

.logo-dots {
 display: flex;
 margin-right: 10px;
}

.blue-dot {
 width: 20px;
 height: 20px;
 border-radius: 50%;
 margin-right: 4px;
}

.blue-dot.dark {
 background-color: #1a56a7;
}

.blue-dot.medium {
 background-color: #3a7cc3;
}

.blue-dot.light {
 background-color: #62a1ef;
}

.logo-text {
 display: flex;
 flex-direction: column;
}

.logo-title {
 font-size: 18px;
 font-weight: 600;
 color: #333;
}

.logo-subtitle {
 font-size: 18px;
 color: #666;
}

.illustration-container {
 flex: 1;
 display: flex;
 justify-content: center;
 align-items: center;
}

.process-illustration {
 max-width: 80%;
 max-height: 80%;
}

.right-section {
 flex: 1;
 background-color: #f8f9fe;
 display: flex;
 justify-content: center;
 align-items: center;
 
 
}

.login-form-wrapper {
 background-color: #FFFFFF;
 border-radius: 12px;
 padding: 40px;
 width: 100%;
 max-width: 600px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
 height: 95vh;
 margin-left: 80px;
}

.login-header {
 margin-bottom: 30px;
}

.login-header h1 {
 font-size: 24px;
 font-weight: 700;
 color: #333;
 margin-bottom: 5px;
 text-align: justify;
 margin-left: -5px;
}

.login-header p {
 font-size: 16px;
 color: #666;
}

.login-form {
 display: flex;
 flex-direction: column;
 
}

.form-group {
 margin-bottom: 5px;
}

.form-group label {
 display: block;
 margin-bottom: 8px;
 font-size: 16px;
 color: #171A1C;
 font-weight: 500;
}

.form-group input {
 width: 100%;
 padding: 12px 16px;
 border: 1px solid #e1e5ed;
 border-radius: 6px;
 font-size: 14px;
 background-color: #F2F5FA;
}

.form-group input:focus {
 outline: none;
 border-color: #3a7cc3;
}

.password-input-container {
 position: relative;
 
}

.toggle-password {
 position: relative;
 right: -350px;
 transform: translateY(-120%);
 background: none;
 border: none;
 cursor: pointer;
 color: #aaa;
}

.remember-me {
 display: flex;
 align-items: center;
 margin-bottom: 10px;
 
}

.checkbox-container {
 display: flex;
 align-items: center;
 position: relative;
 padding-left: 24px;
 cursor: pointer;
 font-size: 14px;
 color: #666;
}

.checkbox-container input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
 height: 0;
 width: 0;
}

.checkmark {
 position: absolute;
 top: 0;
 left: 0;
 height: 16px;
 width: 16px;
 border: 1px solid #cbd5e0;
 border-radius: 4px;
}

.checkbox-container input:checked ~ .checkmark {
 background-color: #2A66B0;
 border-color: #2A66B0;
}

.login-button {
 background-color: #2A66B0;
 color: white;
 border: none;
 border-radius: 6px;
 padding: 14px;
 font-size: 16px;
 font-weight: 500;
 cursor: pointer;
 margin-bottom: 15px;
}

.login-button:hover {
 background-color: #2A66B0;
}

.forgot-password1 {
 text-align: center;
 margin-bottom: 15px;
}

.forgot-password1 a {
 color: #333;
 text-decoration: none;
 font-size: 14px;
}

.divider {
 position: relative;
 text-align: center;
 margin: 20px 0;
}

.divider::before,
.divider::after {
 content: "";
 position: absolute;
 top: 50%;
 width: 45%;
 height: 1px;
 background-color: #e1e5ed;
}

.divider::before {
 left: 0;
}

.divider::after {
 right: 0;
}

.divider span {
 background-color: white;
 padding: 0 10px;
 font-size: 14px;
 color: #666;
}

.google-login-button {
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 12px;
 border: 1px solid #e1e5ed;
 border-radius: 6px;
 background-color: #E1E7FC;
 cursor: pointer;
 margin-bottom: 20px;
}

.google-icon {
 width: 20px;
 height: 20px;
 margin-right: 10px;
}

.signup-prompt {
 font-size: 14px;
 color: #666;
 text-align: center;
}

.signup-prompt a {
 color: #5585C0;
 text-decoration: none;
 font-weight: 500;
}


@media (max-width: 480px)  {
 .login-container {
   flex-direction: column;
   height: 97vh; 
 }
 
 .illustration-container {
   display: none;
 }

 .left-section{
  
   width: 0;
   height: 0;
   padding: 0;
 }
 
 .right-section {
   padding: 0px;
 }
.dhms-logo {
 width: 100px;
}

.login-form-wrapper{
 width: fit-content;
 height: 84vh;
 padding-top: 5px;
 margin-right: 22px;
}

.toggle-password{
 right: -230px;
 
}
 
.right-section{
 padding-top: 0;
}
}


