/* FAQs.css */
.faqs-container {
  width: 70%;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.faqs-subtitle {
  font-size: 16px;
  color: #6b6b6b;
  margin-bottom: 30px;
}

.faq-item {
  border-bottom: 1px solid #000000;
  padding: 15px 0;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-header h3 {
  font-size: 18px;
  margin: 0;
}

.faq-icon {
  font-size: 18px;
  color: #6b6b6b;
}

.faq-body {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 15px;
  color: #333;
}

.view-more {
  font-size: 16px;
  color: #1A73E8;
  cursor: pointer;
  margin: 20px 0;
}

.contact-section {
  text-align: center;
  margin-top: 40px;
}

.contact-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #1A73E8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #155cb0;
}
