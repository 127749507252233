.security-settings1 {
  width: 100%;
  /* max-width: 700px; */
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: ;
}

.wallet-section2 {
  display: flex;
  flex-direction: column;
  
}

.tabs {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.tabs span {
  cursor: pointer;
  padding-bottom: 5px;
  color: #666;
}

.tabs .active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.input-group {
  margin-bottom: 20px;
}

.input-group1 label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
.input-group input {
  width: 150%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #f9f9f9;
  text-align: justify;
}

.pin-inputs {
  display: flex;
  gap: 10px;
  width: 50%;
  margin-left: -120px;
}

.pin-inputs input {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #f9f9f9;
  text-align: center;

}


.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-left: 20px;
}

.cancel-btn {
  background: #f0f0f0;
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn:hover {
  background: #0056b3;
}

.cancel-btn:hover {
  background: #d6d6d6;
}

.sets {
  display: flex;
  flex-direction: row;
  gap: 50px;
 
}

.set1 {
  margin-top: 20px;
}
