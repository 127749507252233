/* 
.topbar {
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 1);
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
}

.topbarwrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logo {
  width: 120px;
  margin-right: 10px;
}

.search-bar-container1 {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
}

.input-wrapper1 {
  display: flex;
  align-items: center;
  width: 400px;
  padding: 0 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

input {
  width: 100%;
  border: none;
  padding: 8px;
  font-size: 14px;
  margin-left: 5px;
}

.topRight {
  display: flex;
  align-items: center;
  gap: 20px;
}

.topbarIconcontainer {
  position: relative;
  cursor: pointer;
  color: #555;
}

.rightRight {
  display: flex;
  align-items: center;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}

.dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 200px;
  right: 0;
  z-index: 100;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.menuButton {
  display: none;
  color: #333;
  cursor: pointer;
  padding: 8px;
}

.search-icon1 {
  width: 10%;
}


@media (max-width: 1024px) {
  .topbarwrapper {
    padding: 0 10px;
  }

  .logo {
    width: 100px;
  }

  .search-bar-container {
    justify-content: flex-start;
  }

  .input-wrapper {
    width: 300px;
  }

  .topRight {
    gap: 10px;
  }

  .topAvatar {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 768px) {
  .topbar {
    height: auto;
    flex-direction: column;
    padding: 10px 0;
    width: 100%
  }

  .menuButton {
    display: block;
    left: 330px;
    top: 24px;
  }
  
   .topbarwrapper {
    padding: 0 10px;
    display: flex;
    align-items: center;
  } 

  .topLeft {
    display: flex;
    align-items: center;
  }

  .search-bar-container {
    flex: 1;
    margin: 0 10px;
  }

  .topRight {
    display: flex;
    align-items: center;
  }
}

  .topbarwrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .search-bar-container {
    order: 1; 
    width: 100%;
    margin-left: 8px;
  }

  .input-wrapper {
    width: 90%;
  }

  .topRight {
    order: 1; 
    width: 100%;
    justify-content: space-between;
    margin-left: -20px;
  }

  .topbarIconcontainer {
    flex: 1;
    text-align: center;
  }

  .rightRight {
    flex-direction: column;
    align-items: flex-start;
  }

  .topAvatar {
    width: 30px;
    height: 30px;
  }

  h3 {
    font-size: 12px;
  }
 */



 /* Default styles for larger screens (desktop) remain unchanged */
.topbar {
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 1);
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
}

.topbarwrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logo {
  width: 120px;
  margin-right: 10px;
}

.search-bar-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 400px;
  padding: 0 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
  margin-left: 5px;
}

.topRight {
  display: flex;
  align-items: center;
  gap: 20px;
}

.topbarIconcontainer {
  position: relative;
  cursor: pointer;
  color: #555;
}

.rightRight {
  display: flex;
  align-items: center;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}

.dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 200px;
  right: 0;
  z-index: 100;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
  .topbarwrapper {
    padding: 0 10px;
  }

  .logo {
    width: 100px;
  }

  .search-bar-container {
    justify-content: flex-start;
  }

  .input-wrapper {
    width: 300px;
  }

  .topRight {
    gap: 10px;
  }

  .topAvatar {
    width: 35px;
    height: 35px;
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .topbar {
    height: auto;
    flex-direction: column;
    padding: 10px 0;
    width: 99%
  }

  .menuButton {
    display: block;
    left: 329px;
    top: 24px;
  }

  .topbarwrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .search-bar-container {
    order: 1; /* Move the search bar below the logo */
    width: 100%;
  }

  .input-wrapper {
    width: 90%;
  }

  .topRight {
    order: 2; /* Move icons below the search bar */
    width: 100%;
    justify-content: space-between;
  }

  .topbarIconcontainer {
    flex: 1;
    text-align: center;
  }

  .rightRight {
    flex-direction: column;
    align-items: flex-start;
  }

  .topAvatar {
    width: 30px;
    height: 30px;
  }

  h3 {
    font-size: 12px;
  }
}
