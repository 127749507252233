.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    position: relative;
    height: 40%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modal-content h2 {
    margin: 0 0 10px;
  }
  
  .account-details p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 5px;
  }
  
  .account-details {
    background-color: rgba(42, 102, 176, 1);
    color: white;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    height: 25%;
  }
  
  .account-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .account-value {
    font-weight: bold;
  }
  
  .copy-icon {
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
  }
  
  .instructions {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
  }

  .instructions p {
    line-height: 20px;
  }
  
  .info-icon {
    font-size: 18px;
    margin-right: 5px;
    color: #1A73E8;
  }
  
  .instructions-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Adjust spacing between header and paragraph if needed */
  }
  .instructions h4 {
    margin: 0;
  }