.filter-bar {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  margin: 20px 0;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1200px;
}

.filter-search-container {
  position: relative;
  flex: 1;
  min-width: 200px;
}

.filter-search {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: all 0.2s ease;
}

.filter-search:focus {
  outline: none;
  border-color: #666;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.filter-icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 15px;
  transition: all 0.2s ease;
  background: white;
  min-width: fit-content;
}

.filter-icon-container:hover {
  background: #f5f5f5;
  border-color: #999;
}

.filter-icon-container:active {
  background: #eee;
  transform: scale(0.98);
}

.filter-icon {
  color: #808080;
  font-size: 24px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.filter-text {
  font-size: 16px;
  color: #808080;
  white-space: nowrap;
}

/* Tablet Styles */
@media screen and (max-width: 768px) {
  .filter-bar {
    padding: 12px;
    gap: 12px;
  }

  .filter-search {
    padding: 10px 12px;
    font-size: 15px;
  }

  .filter-icon-container {
    padding: 8px 12px;
  }

  .filter-icon {
    font-size: 22px;
    margin-right: 6px;
  }

  .filter-text {
    font-size: 15px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 480px) {
  .filter-bar {
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    gap: 10px;
  }

  .filter-search-container {
    width: 100%;
  }

  .filter-search {
    padding: 10px;
    font-size: 14px;
  }

  .filter-icon-container {
    justify-content: center;
    padding: 10px;
  }

  .filter-icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .filter-text {
    font-size: 14px;
  }
}

/* Small Mobile Styles */
@media screen and (max-width: 320px) {
  .filter-bar {
    padding: 8px;
    gap: 8px;
  }

  .filter-icon-container {
    padding: 8px;
  }

  .filter-text {
    display: none;
  }

  .filter-icon {
    margin-right: 0;
  }
}