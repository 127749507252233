@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

.task-compl {
  background: rgb(251, 251, 255);
  height: 33.1%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Nunito", sans-serif;
  margin-right: 10px;
  margin-top: 20px;
  margin-left: -80px;
  padding: 10px;
}

.comtempt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.see-all {
  font-size: 12px;
  color: blue;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
 
}

.task-item {
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 5.7px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.task-comp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.task-label {
  font-size: 12px;
  color: #888;
  flex: 1;
}

.task-value {
  font-size: 13px;
  font-weight: 600;
  flex: 2;
  text-align: right;
  color: #333;
}

.rating-stars {
  display: flex;
  align-items: center;
  gap: 4px;
}

.star {
  color: gold;
  font-size: 16px !important;
}

.star.gray {
  color: lightgray;
}

.starNum {
  font-size: 12px;
  color: #555;
  margin-left: 5px;
}
